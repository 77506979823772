import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LikeForm.css';

const LikeForm = ({ onSubmit, onHowDoesItWorkClick }) => {
  const [likedEmail, setLikedEmail] = useState('');
  const [message, setMessage] = useState('');
  const [cooldown, setCooldown] = useState(0);
  const [matchedEmails, setMatchedEmails] = useState([]);
  const [anonymizedLikes, setAnonymizedLikes] = useState([]);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [pendingLikeEmail, setPendingLikeEmail] = useState('');

  useEffect(() => {
    const fetchMatchedEmailsAndLikes = async () => {
      try {
        const response = await axios.get('/api/matches', { withCredentials: true });
        setMatchedEmails(response.data.matched_emails);
        setAnonymizedLikes(response.data.anonymized_likes);
      } catch (error) {
        console.error('error fetching matched emails and likes:', error);
      }
    };

    fetchMatchedEmailsAndLikes();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@uchicago\.edu$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(likedEmail)) {
      setMessage('please enter a valid @uchicago.edu email.');
      return;
    }

    try {
      const response = await axios.post(
        '/api/check-like',
        { liked_email: likedEmail },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200 && !response.data.error) {
        setPendingLikeEmail(likedEmail);
        setConfirmationVisible(true);
      } else {
        setMessage(response.data.message || 'error submitting like.');
      }
    } catch (error) {
      if (error.response?.data?.remaining_time) {
        setCooldown(error.response.data.remaining_time);
        setMessage('');
      } else if (error.response?.data?.error) {
        setMessage(error.response.data.error);
      } else {
        setMessage('error submitting like.');
      }
    }
  };

  const handleConfirmLike = async () => {
    setConfirmationVisible(false);

    try {
      const response = await axios.post(
        '/api/like',
        { liked_email: pendingLikeEmail },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      setMessage(response.data.message || 'like submitted successfully!');

      const updatedResponse = await axios.get('/api/matches', { withCredentials: true });
      setMatchedEmails(updatedResponse.data.matched_emails);
      setAnonymizedLikes(updatedResponse.data.anonymized_likes);

      if (onSubmit) {
        onSubmit(pendingLikeEmail);
      }
    } catch (error) {
      if (error.response?.data?.error) {
        setMessage(error.response.data.error);
        if (error.response.data.remaining_time) {
          setCooldown(error.response.data.remaining_time);
        }
      } else {
        setMessage('error submitting like.');
      }
    }
  };

  const handleCancelLike = () => {
    setConfirmationVisible(false);
    setPendingLikeEmail('');
  };

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown((prevCooldown) => prevCooldown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [cooldown]);

  const formatCooldown = (cooldownInSeconds) => {
    const oneDay = 24 * 60 * 60;
    const oneHour = 60 * 60;
    const oneMinute = 60;

    if (cooldownInSeconds >= oneDay) {
      const days = Math.floor(cooldownInSeconds / oneDay);
      return `you must wait ${days} day${days > 1 ? 's' : ''} before liking again.`;
    } else if (cooldownInSeconds >= oneHour) {
      const hours = Math.floor(cooldownInSeconds / oneHour);
      return `you must wait ${hours} hour${hours > 1 ? 's' : ''} before liking again.`;
    } else if (cooldownInSeconds >= oneMinute) {
      const minutes = Math.floor(cooldownInSeconds / oneMinute);
      return `you must wait ${minutes} minute${minutes > 1 ? 's' : ''} before liking again.`;
    } else {
      return 'you must wait 1 minute before liking again.';
    }
  };

  return (
    <div className="like-form-container">
      <h2 className="form-title">submit a like</h2>
      <form onSubmit={handleSubmit} className="like-form">
        <input
          type="text"
          value={likedEmail}
          onChange={(e) => setLikedEmail(e.target.value)}
          placeholder="enter uchicago email"
          disabled={cooldown > 0}
          className="like-input"
        />
        <button type="submit" className="like-button" disabled={cooldown > 0}>
          submit
        </button>
      </form>

      {message && <p className="message">{message}</p>}
      {cooldown > 0 && <p className="cooldown-timer">{formatCooldown(cooldown)}</p>}

      {/* The two columns side by side */}
      <div className="info-columns">
        <div className="column">
          <h3>your matches</h3>
          {matchedEmails.length > 0 ? (
            <ul className="matched-list">
              {matchedEmails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
          ) : (
            <p>no matches yet.</p>
          )}
        </div>

        <div className="column">
          <h3># of people who liked you</h3>
          {anonymizedLikes.length > 0 ? (
            <p>
              {anonymizedLikes.length} like
              {anonymizedLikes.length > 1 ? 's' : ''} received
            </p>
          ) : (
            <p>no likes yet.</p>
          )}
        </div>
      </div>

      {/* Moved "how does it work?" outside the .info-columns so it sits near the bottom */}
      <div className="how-does-it-work-container">
        <span onClick={onHowDoesItWorkClick} className="header-login-link">
          how does it work?
        </span>
      </div>

      {confirmationVisible && (
        <div className="confirmation-popup">
          <p>confirm like submission for {pendingLikeEmail}?</p>
          <div className="popup-buttons">
            <button className="like-button confirm-yes" onClick={handleConfirmLike}>
              Yes
            </button>
            <button className="like-button confirm-no" onClick={handleCancelLike}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LikeForm;
