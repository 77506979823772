import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import LikeForm from './components/LikeForm';
import Footer from './components/Footer';
import Header from './components/Header';
import About from './components/About';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import Deactivate from './components/Deactivate';
import Unsubscribe from './components/Unsubscribe';
import AccountDeactivated from './components/AccountDeactivated';
import UnsubscribeConfirm from './components/UnsubscribeConfirm';
import DeactivateConfirm from './components/DeactivateConfirm';
import ClearLikes from './components/ClearLikes';
import NotificationBox from './components/NotificationBox';
import FirstTimeLoginPopup from './components/FirstTimeLoginPopup';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [matchMessage, setMatchMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);

  const checkAuthentication = async () => {
    try {
      console.log('Checking authentication status...');
      const response = await fetch('/api/auth-status', {
        credentials: 'include',
      });
      const data = await response.json();
      if (data.is_authenticated) {
        setIsAuthenticated(true);
        setIsDeactivated(data.user.is_deactivated);
        if (data.user.is_first_time_login) {
          setIsFirstTimeLogin(true);
        }
        localStorage.setItem('userSession', JSON.stringify(data.user));
      } else {
        setIsAuthenticated(false);
        setIsDeactivated(false);
      }
    } catch (error) {
      console.error('Authentication check failed:', error);
      setIsAuthenticated(false);
      setIsDeactivated(false);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        setIsAuthenticated(false);
        setIsDeactivated(false);
        localStorage.removeItem('userSession');
        console.log('User logged out successfully');
      } else {
        const data = await response.json();
        setMatchMessage(data.error || 'Logout failed.');
      }
    } catch (error) {
      console.error('Logout failed:', error);
      setMatchMessage('Logout failed.');
    }
  };

  const handleLikeSubmit = async (likedEmail) => {
    try {
      console.log('Submitting like for email:', likedEmail);

      const response = await fetch('/api/like', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ liked_email: likedEmail }),
        credentials: 'include',
      });

      const data = await response.json();

      if (response.ok) {
        setMatchMessage(data.message || 'Like submitted successfully!');
      } else {
        setMatchMessage(data.error || 'Error submitting like.');
      }
    } catch (error) {
      console.error('Error during like submission:', error);
      setMatchMessage('Error submitting like.');
    }
  };

  const sendHeartbeat = async () => {
    try {
      await fetch('/api/heartbeat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
        credentials: 'include',
      });
    } catch (error) {
      console.error('Error sending heartbeat:', error);
    }
  };

  const fetchErrorFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error_message');
    if (error) {
      const decodedError = decodeURIComponent(error);
      setErrorMessage(decodedError);
      localStorage.setItem('errorMessage', decodedError);
        window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      const storedError = localStorage.getItem('errorMessage');
      if (storedError) {
        setErrorMessage(storedError);
        localStorage.removeItem('errorMessage');
      }
    }
  };
  

  useEffect(() => {
    const sessionData = localStorage.getItem('userSession');
    if (sessionData) {
      const parsedData = JSON.parse(sessionData);
      setIsAuthenticated(true);
      setIsDeactivated(parsedData.is_deactivated);
    } else {
      checkAuthentication();
    }

    sendHeartbeat();
    fetchErrorFromUrl();

    const interval = setInterval(sendHeartbeat, 2 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Header 
          isAuthenticated={isAuthenticated} 
          isDeactivated={isDeactivated} 
          handleLogout={handleLogout} 
        />

        <div className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  isDeactivated ? (
                    <Navigate to="/account-deactivated" replace />
                  ) : (
                    <>
                      <LikeForm 
                        onSubmit={handleLikeSubmit} 
                        onHowDoesItWorkClick={() => setIsFirstTimeLogin(true)} 
                        />
                    </>
                  )
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />

            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/" replace />
                ) : (
                  <Login />
                )
              }
            />

            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/deactivate" element={<Deactivate />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/account-deactivated" element={<AccountDeactivated />} />

            <Route path="/unsubscribe/confirm" element={<UnsubscribeConfirm />} />
            <Route path="/deactivate/confirm" element={<DeactivateConfirm />} />
            <Route path="/clear-likes" element={<ClearLikes />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>

        <Footer isAuthenticated={isAuthenticated} />

        {errorMessage && <NotificationBox message={errorMessage} />}

        {isFirstTimeLogin && <FirstTimeLoginPopup onClose={() => setIsFirstTimeLogin(false)} />}
      </div>
    </Router>
  );
}

export default App;
